import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import "./Animation.css"
import Animation from './Animation';
import Animationn from './Animationn';
const targetDate = '2023-07-25T00:00:00';

const CountdownTimer = () => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function calculateTimeRemaining() {
    const timeDiff = Date.parse(targetDate) - Date.now();
    const seconds = Math.floor((timeDiff / 1000) % 60);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  }

  return (
    <div className="countdown-timer">
      <img src="images/logotest.png" style={{width:300}}/>
      <div className='animm'>
      <Animationn/>
      </div>
      <div className='anim'>
      <Animation/>
      </div>
      <div className="countdown">
        <div className="time-block">
          <div className="time">{String(timeRemaining.days).padStart(2, '0')}</div>
          <div className="label">Days</div>
        </div>
        <div className="time-block">
          <div className="time">{String(timeRemaining.hours).padStart(2, '0')}</div>
          <div className="label">Hours</div>
        </div>
        <div className="time-block">
          <div className="time">{String(timeRemaining.minutes).padStart(2, '0')}</div>
          <div className="label">Minutes</div>
        </div>
        <div className="time-block">
          <div className="time">{String(timeRemaining.seconds).padStart(2, '0')}</div>
          <div className="label">Seconds</div>
        </div>
      </div>
      <img src="images/qrcode.jpeg" style={{width:100}}/>
    </div>
  );
};



export default CountdownTimer;
