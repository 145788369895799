import React from 'react'
import "./Animationn.css"

const Animationn = () => {
  return (
    <div>
    <button class="btn" type="button">
  <strong>LOADING</strong>
  <div id="container-stars">
    <div id="stars"></div>
  </div>

  <div id="glow">
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
</button>
    </div>
  )
}

export default Animationn
