import React from 'react';
import './App.css';
import CountdownTimer from "./component/CountDown" 




function App() {
  return (
    <div className="App">
     
      <CountdownTimer />
    </div>
  );
}

export default App;